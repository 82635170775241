import { Stack, StackProps } from "@mui/material";
import React, { memo, ReactNode, useMemo } from "react";
import { Img } from "./Img";
import {
  CIRCLE_CROWN_ICON,
  GREEN_CIRLCE_BOX_ICON,
  GREEN_CIRLCE_USER_ICON,
  ORANGE_LIGHT_ICON,
  SUCESSFUL_ICON,
} from "assets/icons";
import { LgTextMd, StyledATag, XsText } from "./Text";
import { useIntl } from "react-intl";
import useUser from "hooks/useUser";
import Row from "./Row";
import { PATHS } from "constants/routes";
import { AcceptButton, YellowButton } from "./Button";
import {
  StyledDiv,
  StyledQuoteContactTips,
  StyledUpgradePlanToConnectBuyerTips,
} from "./Tips.styles";
import { VERIFICATION_LEVELS } from "constants/data";

const GreenTips = ({ children, ...rest }: StackProps) => {
  const t = useIntl();
  return (
    <StyledDiv direction="row" spacing={1} {...rest}>
      <div className="title">
        <Img src={ORANGE_LIGHT_ICON} alt="icon" />
      </div>
      {children}
    </StyledDiv>
  );
};

export const QuoteContactTips = memo(() => {
  const t = useIntl();
  const { currentPlan } = useUser();
  const { verification_level } = currentPlan || {};
  const isLevel1OrNull = useMemo(
    () =>
      !verification_level ||
      verification_level === VERIFICATION_LEVELS.level1.title,
    [verification_level]
  );

  return (
    <StyledQuoteContactTips item xs={12}>
      <Stack spacing={2}>
        <Row
          className="content"
          spacing={1}
          direction={{ xs: "column", md: "row" }}
          justifyContent="center"
        >
          <Row spacing={1} justifyContent="center">
            <Img
              src={isLevel1OrNull ? SUCESSFUL_ICON : GREEN_CIRLCE_BOX_ICON}
              alt="icon"
            />
            <LgTextMd className="text" textAlign="center">
              {t.formatMessage(
                {
                  id: isLevel1OrNull
                    ? "verify_your_account_now_to_receive_up_to_quotes_and_contact_credits"
                    : "post_your_product_to_reach_out_to_various_global_buyers_and_receive_quote",
                },
                {
                  s: (code: ReactNode) => <span>{code}</span>,
                  q: 10,
                  c: 20,
                  quote: 5,
                  quote_w: 14,
                }
              )}
            </LgTextMd>
          </Row>
          <StyledATag
            href={
              isLevel1OrNull ? PATHS.membershipFull : PATHS.createProductsFull
            }
          >
            <AcceptButton>
              {t.formatMessage({
                id: isLevel1OrNull ? "verify_business" : "post_product",
              })}
            </AcceptButton>
          </StyledATag>
        </Row>
      </Stack>
    </StyledQuoteContactTips>
  );
});

export const ExploreGlobalBuyersTips = memo(() => {
  const t = useIntl();

  return (
    <StyledQuoteContactTips item xs={12}>
      <Stack spacing={2}>
        <Row
          className="content"
          spacing={1}
          direction={{ xs: "column", md: "row" }}
          justifyContent="center"
        >
          <Row spacing={1} justifyContent="center">
            <Img src={GREEN_CIRLCE_USER_ICON} alt="icon" />
            <LgTextMd className="text" textAlign="center">
              {t.formatMessage({
                id: "explore_global_buyers_to_expand_your_export_opportunities",
              })}
            </LgTextMd>
          </Row>
          <StyledATag href={PATHS.buyers}>
            <AcceptButton>
              {t.formatMessage({
                id: "discover_now",
              })}
            </AcceptButton>
          </StyledATag>
        </Row>
      </Stack>
    </StyledQuoteContactTips>
  );
});

export const UpgradePlanToConnectBuyerTips = memo(() => {
  const t = useIntl();
  return (
    <StyledUpgradePlanToConnectBuyerTips item xs={12}>
      <Row
        className="content"
        spacing={1}
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
      >
        <Row spacing={1} justifyContent="center">
          <div className="icon-box">
            <Img src={CIRCLE_CROWN_ICON} alt="icon" />
          </div>
          <LgTextMd className="text" textAlign="center">
            {t.formatMessage({
              id: "upgrade_the_premium_membership_to_quick_connect_and_more_benefits",
            })}
          </LgTextMd>
        </Row>
        <StyledATag href={PATHS.supplierPricing}>
          <YellowButton>{t.formatMessage({ id: "upgrade_now" })}</YellowButton>
        </StyledATag>
      </Row>
    </StyledUpgradePlanToConnectBuyerTips>
  );
});

export const ReachMoreBuyerTips = memo(() => {
  const t = useIntl();
  return (
    <StyledUpgradePlanToConnectBuyerTips item xs={12}>
      <Row
        className="content"
        spacing={1}
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
      >
        <Row spacing={1} justifyContent="center">
          <div className="icon-box">
            <Img src={CIRCLE_CROWN_ICON} alt="icon" />
          </div>
          <LgTextMd className="text" textAlign="center">
            {t.formatMessage(
              {
                id: "reach_value_x_more_buyers_with_a_premium_membership_expand_your_reach_today",
              },
              {
                s: (code: ReactNode) => <span>{code}</span>,
                chat: <span className="number">99</span>,
                contact: <span className="number">599</span>,
              }
            )}
          </LgTextMd>
        </Row>
        <StyledATag href={PATHS.supplierPricing}>
          <YellowButton>{t.formatMessage({ id: "upgrade_now" })}</YellowButton>
        </StyledATag>
      </Row>
    </StyledUpgradePlanToConnectBuyerTips>
  );
});

export const PostRFQProfileTips = () => {
  const t = useIntl();
  return (
    <GreenTips direction="row">
      <XsText className="desc-text">
        {t.formatMessage(
          { id: "buyers_engage_profiles" },
          {
            value: <span className="semibold number">76%</span>,
            a: (code: ReactNode) => (
              <a href={PATHS.settingFull} target="_blank">
                {code}
              </a>
            ),
          }
        )}
      </XsText>
    </GreenTips>
  );
};

export const PostRFQProductTips = () => {
  const t = useIntl();
  return (
    <GreenTips direction="row">
      <XsText className="desc-text">
        {t.formatMessage(
          { id: "buyers_engage_products" },
          {
            value: <span className="semibold number">80%</span>,
            a: (code: ReactNode) => (
              <a href={PATHS.createProductsFull} target="_blank">
                {code}
              </a>
            ),
          }
        )}
      </XsText>
    </GreenTips>
  );
};

// QuoteContactTips if reward_post_product === 0
// else random one of 3 below components:
// ExploreGlobalBuyersTips
// ReachMoreBuyerTips
// UpgradePlanToConnectBuyerTips
export const FirstRandomTips = () => {
  const { isFreePlan, user } = useUser();

  const components = [
    ExploreGlobalBuyersTips,
    ReachMoreBuyerTips,
    UpgradePlanToConnectBuyerTips,
  ];

  const RandomizedComponent = useMemo(() => {
    if (user?.reward_post_product === 0) {
      return QuoteContactTips;
    }
    const randomIndex = isFreePlan
      ? Math.floor(Math.random() * components.length)
      : 0;
    return components[randomIndex];
  }, [isFreePlan]);

  return <RandomizedComponent />;
};

// random in
// PostRFQProfileTips
// PostRFQProductTips
export const PostRFQRandomTips = () => {
  const components = [PostRFQProfileTips, PostRFQProductTips];
  const RandomizedComponent = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * components.length);
    return components[randomIndex];
  }, []);

  return <RandomizedComponent />;
};

export { GreenTips };
