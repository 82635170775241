import {
  Box,
  styled,
  tooltipClasses,
  Tooltip as TooltipLib,
  TooltipProps,
} from "@mui/material";
import {
  CIRCLE_CROWN_ICON,
  CIRCLE_VERIFIED_ICON,
  InfoIcon,
} from "assets/icons";
import { ReactNode, useMemo } from "react";
import { Img } from "./Img";
import { useIntl } from "react-intl";
import { Text, XsText, XsTextMd } from "./Text";

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipLib {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 5,
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 12px 16px -4px #10182808, 0px 4px 6px -2px #10182814",
    borderRadius: 8,
  },
}));

export const VerifyTooltip = ({
  title,
  children,
}: {
  title: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <Tooltip title={title}>
      <StyledDiv className="tooltip-icon">
        {children || <Img src={CIRCLE_VERIFIED_ICON} alt="icon" />}
      </StyledDiv>
    </Tooltip>
  );
};

export const VerifyRFQTooltip = ({
  isVerified,
  full,
}: {
  isVerified: boolean;
  full?: boolean;
}) => {
  const t = useIntl();

  if (!isVerified) return null;

  return (
    <VerifyTooltip
      title={t.formatMessage({
        id: isVerified
          ? "sourcing_request_is_verified_by_freshdi"
          : "verifying",
      })}
    >
      <Img src={CIRCLE_VERIFIED_ICON} alt="icon" className="verify-icon" />
    </VerifyTooltip>
  );
};

export const CrownTooltip = ({
  title,
  children,
  isFull,
}: {
  title: ReactNode;
  children?: any;
  isFull?: boolean;
}) => {
  const t = useIntl();
  return (
    <Tooltip title={title}>
      <Box
        display="flex"
        alignItems="center"
        className={isFull ? "sponsored-label" : ""}
      >
        {children || (
          <>
            <Img src={CIRCLE_CROWN_ICON} alt="icon" className="crown-icon" />
            {isFull && <span>{t.formatMessage({ id: "premium" })}</span>}
          </>
        )}
      </Box>
    </Tooltip>
  );
};

export const ComingSoonTooltip = () => {
  const t = useIntl();
  return (
    <Tooltip title={t.formatMessage({ id: "coming_soon" })}>
      <StyledDiv className="tooltip-icon">{<InfoIcon />}</StyledDiv>
    </Tooltip>
  );
};

export const LevelTooltip = ({
  level,
  title = "supplier_is_verified_by_freshdi",
}: {
  level: string;
  title?: string;
}) => {
  const t = useIntl();
  const levelTexts = useMemo(
    () => [
      {
        label: "business_information",
        show: ["Level 2", "Level 3", "Level 4"].indexOf(level) > -1,
      },
      {
        label: "business_licenses",
        show: ["Level 3", "Level 4"].indexOf(level) > -1,
      },
      {
        label: "smart_trace_online",
        show: ["Level 4"].indexOf(level) > -1,
      },
    ],
    [level]
  );
  return (
    <StyledLevelTooltip>
      <Text>{t.formatMessage({ id: title })}</Text>
      <ul>
        {levelTexts
          .filter((text) => text.show)
          .map((text) => (
            <li>
              <Text>{t.formatMessage({ id: text.label })}</Text>
            </li>
          ))}
      </ul>
    </StyledLevelTooltip>
  );
};

export const InfoTooltip = ({ msg = "" }: { msg: string }) => {
  const t = useIntl();
  return (
    <Tooltip title={t.formatMessage({ id: msg })}>
      <StyledDiv className="tooltip-icon">{<InfoIcon />}</StyledDiv>
    </Tooltip>
  );
};

const StyledLevelTooltip = styled(Box)({
  p: {
    fontSize: 10,
  },
  ul: {
    paddingInlineStart: 10,
  },
  "& ul li p": {},
});

const StyledDiv = styled("div")`
  display: flex;
  align-items: center;
  img {
    min-width: 16px;
  }
`;
