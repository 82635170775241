import { SVGProps } from "react";

export function FlagIcon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M3.3335 12.5001C3.3335 12.5001 4.16683 11.6667 6.66683 11.6667C9.16683 11.6667 10.8335 13.3334 13.3335 13.3334C15.8335 13.3334 16.6668 12.5001 16.6668 12.5001V2.50008C16.6668 2.50008 15.8335 3.33341 13.3335 3.33341C10.8335 3.33341 9.16683 1.66675 6.66683 1.66675C4.16683 1.66675 3.3335 2.50008 3.3335 2.50008V12.5001ZM3.3335 12.5001V18.3334"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
