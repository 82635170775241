import { Tab, Tabs as TabsLib, TabsProps, styled } from "@mui/material";
import { COLORS, SCROLL_TOP_POSITIONS } from "constants/layout";
import { PATHS } from "constants/routes";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

const Tabs = styled(TabsLib)({
  minHeight: 36,
  borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
  ".MuiTabs-flexContainer": {},
  ".MuiButtonBase-root": {
    padding: "5px 16px",
    textTransform: "initial",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: COLORS.gray[600],
    minHeight: 30,
  },
  ".MuiButtonBase-root.Mui-selected, .MuiButtonBase-root.Mui-selected:hover": {
    color: COLORS.gray[900],
  },
  ".MuiButtonBase-root:hover": {
    color: COLORS.gray[900],
  },
  // ".MuiTabs-scroller": {
  //   padding: "4px 0",
  // },
  ".MuiTabs-indicator": {
    backgroundColor: COLORS.green[700],
  },
  ".MuiButtonBase-root:not(last-child)": {},
});

export const SourcingTabs = (props: TabsProps) => {
  const t = useIntl();
  const { pathname, search } = useLocation();
  const categoryClass = pathname.split("/")[1];

  const tabs = [
    {
      label: "buyers",
      value: PATHS.buyers,
    },
    {
      label: "rfqs",
      value: PATHS.sourcingRequests,
    },
  ];

  const onClickTab = (href: string) => {
    window.location.href = href + search;
  };

  return (
    <StyledSourcingTabs value={categoryClass} {...props}>
      {tabs.map(({ label, value }) => (
        <Tab
          onClick={() => onClickTab(value)}
          key={label}
          label={t.formatMessage({ id: label })}
          value={value.replace("/", "")}
        />
      ))}
    </StyledSourcingTabs>
  );
};

const StyledSourcingTabs = styled(Tabs)(({ theme }) => ({
  position: "sticky",
  top: SCROLL_TOP_POSITIONS.desktopHeader,
  backgroundColor: COLORS.app.bg,
  zIndex: 5,
  [theme.breakpoints.down("md")]: {
    top: 140,
  },
}));

export default Tabs;
