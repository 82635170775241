import { Box, styled } from "@mui/material";
import { Text } from "components/Text";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import Tooltip from "./Tooltip";
import { Img } from "components/Img";
import { APP_ICONS, CIRCLE_VERIFIED_ICON } from "assets/icons";

const BuyerVerfifyTooltip = ({
  data,
  title = "buyer_is_verified_by_freshdi",
}: {
  data?: { is_biz_verified?: number };
  title?: string;
}) => {
  const t = useIntl();

  const levelTexts = useMemo(
    () => [
      {
        label: "verified_business_document",
        show: data?.is_biz_verified,
      },
    ],
    [data]
  );

  return (
    <Tooltip
      title={
        <StyledLevelTooltip>
          <Text>{t.formatMessage({ id: title })}</Text>
          <ul>
            {levelTexts
              .filter((text) => text.show)
              .map((text) => (
                <li>
                  <Text>{t.formatMessage({ id: text.label })}</Text>
                </li>
              ))}
          </ul>
        </StyledLevelTooltip>
      }
    >
      <div>
        <Img src={APP_ICONS.verified} alt="icon" height={12} />
      </div>
    </Tooltip>
  );
};

const StyledLevelTooltip = styled(Box)({
  p: {
    fontSize: 10,
  },
  ul: {
    paddingInlineStart: 10,
  },
  "& ul li p": {},
});

export default BuyerVerfifyTooltip;
