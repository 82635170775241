import React, { MouseEvent, useState } from "react";
import { BaseButton } from "../Button";
import useUser from "hooks/useUser";
import { useDispatch } from "react-redux";
import { useLoading } from "providers/loading";
import { useNotify } from "providers/notify";
import { PATHS } from "constants/routes";
import { QuotationType } from "types/quotaion";
import { apiCreateQuotation } from "services/api/rfq";
import { updateUserPoint } from "services/redux/actions/user";
import { formatError } from "helpers/error";
import { CircleSettingIcon, SendIcon } from "assets/icons";
import { DotStatus } from "../Status";
import { styled } from "@mui/material";
import { useIntl } from "react-intl";
import RFQReview from "../RFQReview";

type Props = {
  reviewQuote?: QuotationType;
  rfqId?: string;
  quotationId?: string;
  source?: string;
};
const RFQReviewButton = ({
  quotationId,
  rfqId,
  reviewQuote,
  source,
}: Props) => {
  const t = useIntl();
  const { userPoint, isLoggedIn } = useUser();
  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { setNotify } = useNotify();
  const [isQuoted, setIsQuoted] = useState(!!quotationId);
  const [openDialog, setOpenDialog] = useState(false);

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickQuotationBtn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isQuoted) {
      window.location.href = PATHS.myQuotationsFull;
    } else {
      setOpenDialog(true);
    }
  };

  const formatData = (data: QuotationType) => {
    const { farm_id, farm_avatar, farm_name, unit, ...rest } = data;
    return { ...rest, rfq_id: rfqId, unit: unit?.value, source };
  };

  const onClickSendQuotation = async () => {
    try {
      if (reviewQuote) {
        setLoading(true);
        await apiCreateQuotation(formatData(reviewQuote));
        dispatch(updateUserPoint(userPoint - 1));
        setIsQuoted(true);
        onCloseDialog();
        setLoading(false);
        setNotify({
          open: true,
          type: "success",
          msg: t.formatMessage({ id: "create_successfully" }),
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setNotify({
        open: true,
        type: "error",
        msg: formatError(error)?.message,
      });
    }
  };

  return (
    <StyledDiv>
      <BaseButton
        fullWidth
        startIcon={isQuoted ? <CircleSettingIcon /> : <SendIcon />}
        onClick={onClickQuotationBtn}
        className="quote-btn"
      >
        {t.formatMessage({
          id: isQuoted ? "manage_quotation" : "send_quotation",
        })}
        {!isQuoted && isLoggedIn && userPoint > 0 && (
          <span className="chip quote">
            -{1} {t.formatMessage({ id: "quote" })} /{" "}
            {t.formatMessage({ id: "n_quotes_left" }, { n: userPoint })}
          </span>
        )}
        {isQuoted && <DotStatus status={{ value: "0", label: "delivered" }} />}
      </BaseButton>

      <RFQReview
        {...{ onClickSendQuotation, reviewQuote }}
        open={openDialog}
        onClose={onCloseDialog}
      />
    </StyledDiv>
  );
};

const StyledDiv = styled("div")(({ theme }) => ({
  ".quote-btn": {
    fontWeight: 500,
    fontSize: 12,
    ".status-label": {
      marginLeft: 8,
    },
    ".quote": {
      fontSize: 10,
    },
  },
  ".MuiButton-startIcon svg": {
    width: 16,
  },
  [theme.breakpoints.down("md")]: {
    ".quote-btn": {
      fontSize: 11,
      svg: {
        width: 15,
        height: 15,
      },
      span: {
        fontSize: 10,
      },
    },
  },
}));

export default RFQReviewButton;
