import { getCountryFlag } from "helpers/address";
import { Img } from "./Img";
import { Typography, TypographyProps, styled } from "@mui/material";
import Row from "./Row";

const CountryFlag = ({
  country,
  country_code,
  ...props
}: TypographyProps & {
  country?: string;
  country_code?: string;
}) => {
  if (!country) return null;

  return (
    <StyledCompanyFlag spacing={0.5} className="country-box">
      {country_code && (
        <Img src={getCountryFlag(country_code).src} alt="flag" />
      )}
      <Typography
        {...props}
        className={"country_flag ellipsis-text " + props.className}
      >
        {country}
      </Typography>
    </StyledCompanyFlag>
  );
};

const StyledCompanyFlag = styled(Row)({
  p: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: "11px",
  },
  width: "fit-content",
  padding: 4.5,
  borderRadius: 5,
  border: "0.5px solid #EAECF0",
  img: {
    width: 15,
    // height: 11,
  },
});

export default CountryFlag;
