import { styled } from "@mui/material";
import { IMG_DOMAIN } from "constants/schemas";
import { useLanguageContext } from "providers/mul-language";
import { useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const HtmlEditor = (props) => {
  const { error = false } = props;
  const { language } = useLanguageContext();
  const [placeholder, setPlaceholder] = useState("");
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["image", "video"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const formatHtml = useMemo(() => {
    if (props?.defaultValue) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = props?.defaultValue;

      const imgElements = tempDiv.querySelectorAll("img");
      imgElements.forEach((img) => {
        const currentSrc = img.getAttribute("src");
        if (
          currentSrc &&
          !currentSrc.startsWith("http") &&
          !currentSrc.startsWith("data:image/")
        ) {
          img.setAttribute("src", `${IMG_DOMAIN}${currentSrc}`);
        }
      });
      return tempDiv.innerHTML || "";
    }
    return "";
  }, [props?.defaultValue]);

  useEffect(() => {
    setPlaceholder(props.placeholder);
  }, [language]);

  return (
    <StyledReactQuill>
      <ReactQuill
        theme="snow"
        key={placeholder}
        {...props}
        className={(props.className || "") + (error ? " error" : "")}
        {...{ placeholder, modules, formats }}
        defaultValue={formatHtml}
      />
    </StyledReactQuill>
  );
};

const StyledReactQuill = styled("div")(({ theme }) => ({
  "& .ql-toolbar": {
    borderRadius: "4px 4px 0 0",
  },
  "& .ql-container": {
    borderRadius: "0 0 4px 4px",
    fontSize: 14,
  },
  "&.error": {
    "& .ql-toolbar, .ql-container": {
      borderColor: "var(--error-500)",
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: 244,
  },
  "& .ql-editor.ql-blank::before": {
    fontStyle: "initial",
    lineHeight: 1.5,
    fontSize: 14,
    color: "#a1a1a1",
  },

  [theme.breakpoints.down("md")]: {
    "& .ql-container, .ql-editor.ql-blank::before": {
      fontSize: 12,
    },
  },
}));

export default HtmlEditor;
