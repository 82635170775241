import { Stack } from "@mui/material";
import { Img } from "../Img";
import { forwardRef, useMemo } from "react";
import { Link, StyledRouterA, XsText, XsTextMd } from "../Text";
import { useIntl } from "react-intl";
import { followRel, formatImg } from "helpers/format";
import ListingChip from "../chips/ListingChip";
import { COLORS } from "constants/layout";
import { DIRECT_PATHS } from "constants/routes";
import { BuyerInfosType, BuyerType } from "types/buyer";
import StyledBuyer from "./Buye.styles";
import BaseBuyerInfors from "components/infos/BaseBuyerInfors";
import RFQ from "./RFQ";
import useBreakpoints from "hooks/useBreakpoint";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

type Props = {
  company?: BuyerType;
  showfullflag?: boolean;
};

const Buyer = forwardRef(({ company, showfullflag }: Props, ref: any) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoints();

  const {
    slug,
    company_logo,
    num_posted_rfq = 0,
    business_types,
    is_seen,
    activity_tags = [],
    no_follow = 0,
    import_countries,
    customer_name = "",
    customer_id = "",
    country_code,
    country,
    last_active = "",
    is_verified = 0,
    categories = [],
    rfqs = [],
    gallery_preview = [],
  } = company || {};

  const baseInfos: BuyerInfosType = {
    customer_name,
    customer_id,
    country_code,
    country,
    company_logo,
    activity_tags,
    last_active,
    is_verified,
  };

  const formattedRfqs = useMemo(
    () => rfqs.slice(0, gallery_preview.length > 0 ? 1 : 2),
    [rfqs, gallery_preview]
  );

  return (
    <StyledBuyer ref={ref} className={is_seen ? "is-seen-box" : ""}>
      <Stack className="info-box" spacing={1}>
        <BaseBuyerInfors company={baseInfos} />
        {num_posted_rfq > 0 && (
          <XsTextMd className="status-label orange-label">
            {intl.formatMessage(
              { id: `qlt_rfq${num_posted_rfq > 1 ? "s" : ""}_posted` },
              { quantity: num_posted_rfq }
            )}
          </XsTextMd>
        )}

        {/* Text infos stack */}
        <Stack spacing={0.5}>
          {categories.length > 0 && (
            <XsText
              color={COLORS.green[600]}
              className="categories-text cutoff-text twoline"
              component="h3"
            >
              {intl.formatMessage({ id: "buyer_of" })}:{" "}
              {categories.map((category, i) => (
                <StyledRouterA
                  to={DIRECT_PATHS.buyerCategory(category.name)}
                  key={i}
                >
                  {category.category_name}
                  {i === categories.length - 1 ? "" : ", "}
                </StyledRouterA>
              ))}
            </XsText>
          )}
          {business_types && (
            <XsText
              className="cutoff-text twoline info-text"
              color={COLORS.gray[500]}
            >
              {intl.formatMessage({ id: "business_types" }) + ": "}
              <span>{business_types}</span>
            </XsText>
          )}

          {import_countries && (
            <XsText
              className="cutoff-text twoline info-text"
              color={COLORS.gray[500]}
            >
              {intl.formatMessage({ id: "import_countries" }) + ": "}
              <span>{import_countries}</span>
            </XsText>
          )}
        </Stack>

        {/* Buyer activity tags */}
        <ListingChip tags={activity_tags} />

        {isMobile && gallery_preview.length > 0 && (
          <div className="scroll-box medias">
            {gallery_preview.map((media, i) => (
              <Img src={formatImg(media, 3)} alt="media" key={i} />
            ))}
          </div>
        )}
      </Stack>

      {/* RFQ list and gallery */}
      {!isMobile && (
        <Stack direction="row" gap={1} className="right-box">
          {formattedRfqs.length > 0 ? (
            formattedRfqs.map((rfq, i) => <RFQ rfq={rfq} key={i} />)
          ) : (
            <div className="rfq" />
          )}

          {gallery_preview.length > 0 && (
            <Link to={`/${slug}`} rel={followRel(no_follow)} className="media">
              <Swiper
                className="medias"
                modules={[Pagination]}
                pagination={{
                  clickable: true,
                }}
              >
                {gallery_preview.map((media, i) => (
                  <SwiperSlide key={i}>
                    <Img
                      src={formatImg(media, 3)}
                      alt="media"
                      height="100%"
                      width="100%"
                      style={{ objectFit: "cover" }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Link>
          )}
        </Stack>
      )}
    </StyledBuyer>
  );
});

export default Buyer;
