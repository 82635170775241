import { SVGProps } from "react";

export function LinkIcon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg {...{ width, height, viewBox }} fill="none" xmlns={xmlns} {...props}>
      <g clipPath="url(#clip0_12934_112282)">
        <path
          d="M8.33326 10.834C8.69113 11.3124 9.14772 11.7083 9.67205 11.9947C10.1964 12.2812 10.7762 12.4516 11.3721 12.4942C11.9681 12.5369 12.5662 12.4509 13.126 12.2421C13.6858 12.0333 14.1942 11.7065 14.6166 11.284L17.1166 8.78396C17.8756 7.99811 18.2956 6.9456 18.2861 5.85312C18.2766 4.76063 17.8384 3.71558 17.0658 2.94304C16.2933 2.17051 15.2482 1.73231 14.1558 1.72281C13.0633 1.71332 12.0108 2.1333 11.2249 2.89229L9.79159 4.31729M11.6666 9.16729C11.3087 8.68885 10.8521 8.29297 10.3278 8.00651C9.80347 7.72004 9.22367 7.54969 8.62771 7.50701C8.03176 7.46433 7.4336 7.55032 6.8738 7.75914C6.314 7.96796 5.80566 8.29473 5.38326 8.71729L2.88326 11.2173C2.12426 12.0031 1.70429 13.0556 1.71378 14.1481C1.72327 15.2406 2.16148 16.2857 2.93401 17.0582C3.70655 17.8307 4.7516 18.2689 5.84408 18.2784C6.93657 18.2879 7.98908 17.8679 8.77492 17.109L10.1999 15.684"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12934_112282">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
