import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { objectToQueryString } from "helpers/search";
import useGetData from "hooks/useGetData";
import useNavigate from "hooks/useNavigate";
import useSearchParam from "hooks/useSearchParam";
import useUser from "hooks/useUser";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { apiGetAppConfigs } from "services/api/helpers";
import { apiGetCountNotiMenuBar } from "services/api/notification";
import { setMenuCountData } from "services/redux/actions/common-data";
import { reloadMenuCountDataSelector } from "services/redux/selectors/userCommonData";
import { BreadscrumbType } from "types/breadscrumb";
import { AppConfigsDataType } from "types/common";

const CommonDataContext = createContext<{
  selectedCategory?: string;
  appConfigs?: any;
  setSelectedCategory: Dispatch<SetStateAction<string | undefined>>;
  onChangeSelectedCategory: (category: string, openNewTab?: boolean) => void;
  onChangeBreadscrumbs: (breadscumbs?: BreadscrumbType[]) => void;
  breadscumbs?: BreadscrumbType[];
}>({
  selectedCategory: undefined,
  setSelectedCategory: () => {},
  onChangeSelectedCategory: () => {},
  onChangeBreadscrumbs: () => {},
  breadscumbs: [],
});

export const CommonDataProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { navigate } = useNavigate();
  const { isLoggedIn } = useUser();
  const dispatch = useDispatch();
  const { searchParams } = useSearchParam();
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    searchParams.get(SEARCH_PARAMS.category_id) || ""
  );
  const [breadscumbs, setBreadscumbs] = useState<BreadscrumbType[]>([]);

  const reloadMenuCountData = useSelector(reloadMenuCountDataSelector);

  const { data: appConfigsData } = useGetData({
    promise: apiGetAppConfigs,
  });
  const { data: unSeenData } = useGetData({
    promise: isLoggedIn ? apiGetCountNotiMenuBar : () => null,
    deps: [reloadMenuCountData, isLoggedIn],
  });

  const appConfigs = useMemo(() => {
    const { country = [], uom = [] } = (appConfigsData?.data ||
      {}) as AppConfigsDataType;
    const countries = country.map(
      ({ name, country_name, code, custom_calling_code }) => ({
        label: name,
        value: country_name,
        code,
        custom_calling_code,
      })
    );
    const uoms = uom.map(({ name, uom_name }) => ({
      label: uom_name,
      value: name,
    }));
    return { countries, uoms };
  }, [appConfigsData]);

  const onChangeSelectedCategory = (category: string, openNewTab?: boolean) => {
    setSelectedCategory(category);
    let newUrl = `${PATHS.sourcingRequests}?${objectToQueryString({
      category_id: category,
    })}`;
    if (openNewTab) {
      window.open(newUrl);
    } else {
      navigate(newUrl);
    }
  };

  const onChangeBreadscrumbs = (breadscumbs?: BreadscrumbType[]) => {
    setBreadscumbs(breadscumbs || []);
  };

  useEffect(() => {
    if (unSeenData?.data) {
      dispatch(setMenuCountData(unSeenData?.data || {}));
    }
  }, [unSeenData, dispatch]);

  return (
    <CommonDataContext.Provider
      value={{
        selectedCategory,
        setSelectedCategory,
        appConfigs,
        onChangeSelectedCategory,
        onChangeBreadscrumbs,
        breadscumbs,
      }}
    >
      {children}
    </CommonDataContext.Provider>
  );
};

export const useCommonData = () => useContext(CommonDataContext);
