import React from "react";
import { Box, Typography, Stack, styled } from "@mui/material";

import { validateCommon } from "services/utils/helpers";

const NotificationItem = ({ data, onClick }) => {
  let backgroundColor = parseInt(data?.is_read) === 0 ? "#FFF6EF" : "white";

  return (
    <StyledNotificationItem
      p={2}
      backgroundColor={backgroundColor}
      onClick={() => onClick(data)}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight="bold" fontSize="13px" color="black" mb={1}>
          {data?.data?.title}
        </Typography>
        <Typography fontSize="10px" sx={{ color: "#666666" }}>
          {validateCommon("formatCreation", data?.creation)}
        </Typography>
      </Stack>
      <Typography fontSize="10px" sx={{ color: "#666666" }}>
        {data?.data?.body}
      </Typography>
    </StyledNotificationItem>
  );
};

const StyledNotificationItem = styled(Box)({
  border: "solid #D3D3D3 1px",
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  cursor: "pointer",
});

export default NotificationItem;
