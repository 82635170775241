import http from "./http";

const ENDPOINT = "/api/method/freshdi.api.web.v3";

export const apiGetCountUnseenNotifications = () =>
  http.get(`${ENDPOINT}.notification.count_unseen_notifications`);

export const apiGetNotifications = (pageLimit: {
  offset: number;
  limit: number;
}) =>
  http.get(`${ENDPOINT}.notification.get_supplier_notifications`, {
    params: pageLimit,
  });

export const apiMarkAllNotificationSeen = () =>
  http.get(`${ENDPOINT}.notification.supplier_mark_all_as_seen`);

export const apiMarkNotificationSeen = (id: string) =>
  http.post(`${ENDPOINT}.notification.supplier_mark_as_seen`, { noti_id: id });

export const apiGetCountNotiMenuBar = () =>
  http.get(`${ENDPOINT}.notification.count_noti_menu_bar`);
