import { ButtonProps, styled } from "@mui/material";
import { APP_ICONS } from "assets/icons";
import { BaseButton } from "components/Button";
import { Img } from "components/Img";
import { COLORS } from "constants/layout";

const GenerateButton = (props: ButtonProps) => (
  <StyledGenerateButton
    endIcon={<Img src={APP_ICONS.sparkless} />}
    {...props}
  />
);

const StyledGenerateButton = styled(BaseButton)({
  borderRadius: 13,
  width: "fit-content",
  padding: "5px 12px",
  lineHeight: 1,
  fontSize: 10,
  border: "none",
  "&,:hover": { backgroundColor: COLORS.green[600] },
  color: "#fff",
  img: {
    height: 12,
    // marginLeft: 4,
  },
  ".MuiButton-endIcon": {
    marginLeft: 4
  }
});

export default GenerateButton;
