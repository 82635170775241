import { SVGProps } from "react";

export function CrownIcon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg {...{ width, height, viewBox }} fill="none" xmlns={xmlns} {...props}>
      <g clipPath="url(#clip0_13004_76948)">
        <path
          d="M0.833252 13.332H19.1666"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M19.1666 4.16667L14.9999 7.5L9.99992 2.5L4.99992 7.5L0.833252 4.16667V15C0.833252 15.442 1.00885 15.866 1.32141 16.1785C1.63397 16.4911 2.05789 16.6667 2.49992 16.6667H17.4999C17.9419 16.6667 18.3659 16.4911 18.6784 16.1785C18.991 15.866 19.1666 15.442 19.1666 15V4.16667Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_13004_76948">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
