import { Badge, Box, IconButton, Popover, styled } from "@mui/material";
import { BellIcon, DubbleCheckIcon, MarkCheckIcon } from "assets/icons";
import { MouseEvent, memo, useEffect, useMemo, useState } from "react";
import Row from "./Row";
import { SmTextMd, Text, XsText } from "./Text";
import useGetData from "hooks/useGetData";
import useIntersectionObserver from "hooks/useInfinityScroll";
import { LoadingSkeleton } from "./LoadingSkeleton";
import { reloadNotiSelector } from "services/redux/selectors/notiSelectoer";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  apiGetCountUnseenNotifications,
  apiGetNotifications,
  apiMarkAllNotificationSeen,
  apiMarkNotificationSeen,
} from "services/api/notification";
import { limitCountNoti } from "helpers/string";
import { NotificationType } from "types/notification";
import { Tooltip } from "./Tooltip";
import { useLoading } from "providers/loading";
import { onReloadNoti } from "services/redux/actions/notification";
import TimeDiff from "./TimeDiff";
import dayjs from "dayjs";
import HtmlViewer from "./HtmlViewer";

type Props = {};

type ShowNotificationType = {
  open: boolean;
  anchorEl?: any;
};

const Notification = (props: Props) => {
  const t = useIntl();
  const reloadNoti = useSelector(reloadNotiSelector);
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const [showNotifications, setShowNotifications] =
    useState<ShowNotificationType>({
      open: false,
      anchorEl: null,
    });
  const [pageLimit, setPageLimit] = useState({
    offset: 0,
    limit: 20,
  });
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [blocker, setBlocker] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { data: countUnSeenNotiData } = useGetData({
    promise: apiGetCountUnseenNotifications,
    deps: [reloadNoti],
  });
  const countUnSeenNoti = useMemo(
    () => countUnSeenNotiData?.data?.count || 0,
    [countUnSeenNotiData]
  );

  const onCloseNotifications = () => setShowNotifications({ open: false });

  const onOpenNotifications = (e: MouseEvent<HTMLButtonElement>) =>
    setShowNotifications({ open: true, anchorEl: e.currentTarget });

  const lastNotificationRef = useIntersectionObserver(
    () => {
      setPageLimit({
        ...pageLimit,
        offset: pageLimit.offset + pageLimit.limit,
      });
    },
    isLoading,
    !blocker
  );

  const onClickMarkSeenAll = async () => {
    try {
      setLoading(true);
      await apiMarkAllNotificationSeen();
      dispatch(onReloadNoti());
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    if (countUnSeenNoti > 0) {
    }
  };

  const onClickNotification = (noti: NotificationType) => {
    if (!noti.seen) {
      setLoading(true);
      apiMarkNotificationSeen(noti.name)
        .then(() => {
          dispatch(onReloadNoti());
          window.location.href = noti.url;
        })
        .finally(() => setLoading(false));
    } else {
      window.location.href = noti.url;
    }
  };

  useEffect(() => {
    setPageLimit({ ...pageLimit, offset: 0 });
    setNotifications([]);
    setBlocker(false);
  }, [reloadNoti]);

  useEffect(() => {
    setIsLoading(true);
    apiGetNotifications(pageLimit)
      .then((res) => {
        const appendNotifications =
          res?.data?.message?.data?.notifications || [];
        if (appendNotifications.length > 0) {
          setNotifications([...notifications, ...appendNotifications]);
          setBlocker(false);
        } else {
          setBlocker(true);
        }
      })
      .finally(() => setIsLoading(false));
  }, [pageLimit]);

  return (
    <div>
      <StyledIconButton onClick={onOpenNotifications}>
        <Badge badgeContent={limitCountNoti(countUnSeenNoti)}>
          <BellIcon />
        </Badge>
      </StyledIconButton>

      <Popover
        open={showNotifications.open}
        onClose={onCloseNotifications}
        anchorEl={showNotifications.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ ".MuiPaper-root": { width: 304, borderRadius: 2 } }}
      >
        <StyledNotifications>
          <Row justifyContent="space-between" className="header">
            <SmTextMd>{t.formatMessage({ id: "notification" })}</SmTextMd>
            <Tooltip
              title={
                <XsText>{t.formatMessage({ id: "mark_all_as_read" })}</XsText>
              }
            >
              <div onClick={onClickMarkSeenAll}>
                <MarkCheckIcon />
              </div>
            </Tooltip>
          </Row>
          <Box className="notifications">
            {notifications.map((noti: NotificationType, i: number) => {
              const { creation, subject, seen } = noti;
              return (
                <Box
                  key={i}
                  className={"notification " + (seen ? "seen" : "")}
                  ref={
                    i === notifications.length - 1 ? lastNotificationRef : null
                  }
                  onClick={() => onClickNotification(noti)}
                >
                  <HtmlViewer className="noti-subject" content={subject} />
                  <Row mt={0.5} justifyContent="space-between">
                    <Text className="time">
                      <TimeDiff
                        startTime={dayjs(creation).format()}
                        endTime={dayjs().format()}
                      />
                    </Text>

                    {seen ? (
                      <Text className="time">
                        <DubbleCheckIcon />
                        {t.formatMessage({ id: "seen" })}
                      </Text>
                    ) : null}
                  </Row>
                </Box>
              );
            })}
            {isLoading && <LoadingSkeleton count={2} mb={2} />}
            {!isLoading && notifications.length < 1 && (
              <XsText align="center" mt={4} mb={6}>
                {t.formatMessage({ id: "no_records" })}
              </XsText>
            )}
          </Box>
        </StyledNotifications>
      </Popover>
    </div>
  );
};

const StyledIconButton = styled(IconButton)({
  padding: 0,
  ".MuiBadge-badge": {
    border: "1px solid #F04438",
    backgroundColor: "#F04438",
    color: "#fff",
    fontSize: 12,
    height: 15,
    width: 15,
    minWidth: 0,
  },
});

const StyledNotifications = styled(Box)({
  border: "1px solid #F2F4F7",
  boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
  backgroundColor: "#fff",
  ".header": {
    padding: 8,
    svg: {
      cursor: "pointer",
    },
  },
  ".notifications": {
    maxHeight: 334,
    overflow: "hidden",
    overflowY: "scroll",
    ".notification": {
      padding: "10px 14px",
      cursor: "pointer",
      backgroundColor: "#F2F4F7",
      ".time": {
        fontSize: 10,
        color: "var(--gray)",
        svg: {
          height: 7,
        },
      },
      ".noti-subject": {
        fontSize: 14,
        color: "#101828",
      },
      "&.seen": {
        backgroundColor: "#fff",
      },
    },
  },
});

export default memo(Notification);
