import { withLoading } from "hocs/withLoading";
import { lazy } from "react";

export { default as ExpandMore } from "./ExpandMore";
export { default as Title } from "./Title";
export { default as Loading } from "./Loading";
export { default as NotificationItem } from "./NotificationItem";
export { default as SnackbarVoucher } from "./SnackbarVoucher";
export { default as IOSSwitch } from "./IOSSwitch";
export { default as LineSplitSection } from "./LineSplitSection";
export { default as DynamicMedia } from "./DynamicMedia";
export { default as UploadProcess } from "./UploadProcess";
export { default as DynamicThumbnail } from "./DynamicThumbnail";
export { default as ConfirmBox } from "./ConfirmBox";
export { default as HtmlEditor } from "./HtmlEditor";
export { default as HtmlViewer } from "./HtmlViewer";
export { default as Row } from "./Row";
export { default as Pagnigation } from "./Pagnigation";
export { default as QuotationStatus } from "./QuotationStatus";
export { default as Quotation } from "./Quotation";
export { default as TextOnLine } from "./TextOnLine";
export { default as IntlMsg } from "./IntlMsg";
export { default as Tabs } from "./Tabs";
export { default as TimeDiff } from "./TimeDiff";
export { default as Attachment } from "./Attachment";
export { default as FreshdiLogo } from "./FreshdiLogo";
export { default as SelectLanguage } from "./SelectLanguage";
export { default as AuthBar } from "./infos/AuthBar";
export { default as Menu } from "./Menu";
export { default as RFQCompanyBox } from "./RFQCompanyBox";
export { default as Breadcrumbs } from "./Breadcrumbs";
export { default as HeaderServices } from "./HeaderServices";
export { default as ScrollToTop } from "./ScrollToTop";
export * from "./ErrorText";
export * from "./Container";
export * from "./Img";
export * from "./LoadingSkeleton";
export * from "./Button";
export * from "./Input";
export * from "./CircularLoading";
export * from "./Text";
export * from "./ErrorText";
export { default as UploadFiles } from "./UploadFiles";
export { default as CompanyBox } from "./CompanyBox";
export { default as Buyer } from "./card/Buyer";
export { default as Swiper } from "./Swiper";
export { default as Categories } from "./Categories";
export { default as RFQ } from "./card/RFQ";
export { default as Blocker } from "./Blocker";
export { default as OTPInput } from "./OTPInput";
export const ChipList = lazy(() => import("./ChipList"));
export * from "./Tooltip";
export { default as PlanLevel } from "./PlanLevel";
export { default as Notification } from "./Notification";
export { default as ContactBuyerBlocker } from "./ContactBuyerBlocker";
export { default as Lock } from "./Lock";
export const SwitchAccountPopup = lazy(() => import("./SwitchAccountPopup"));
export const RelatedSearches = withLoading(
  lazy(() => import("./RelatedSearches")),
  0
);
export const Inquiry = lazy(() => import("./Inquiry"));
export { default as Popover } from "./Popover";
export const HeaderChat = lazy(() => import("./HeaderChat"));
export const ChatList = lazy(() => import("./ChatList"));
export const Chat = lazy(() => import("./Chat"));
export const MobileChatDetail = lazy(() => import("./MobileChatDetail"));
export { default as StyledImg } from "./StyledImg";
export const ReportForm = withLoading(
  lazy(() => import("./ReportForm")),
  2
);
export const InstructionPopup = withLoading(
  lazy(() => import("./InstructionPopup")),
  2
);
export { default as RFQStatus } from "./RFQStatus";

export * from "./Autocomplete";
export * from "./Tips";
export * from "./Status";
export * from "./TipBanners";
export * from "./AnimationText";
export * from "./Checkbox";
export const ContactButton = withLoading(
  lazy(() => import("./button/ContactButton"))
);

export { default as AIGenerateInput } from "./input/AIGenerateInput";
export { default as BaseBuyerInfors } from "./infos/BaseBuyerInfors";
export { default as Select } from "./input/Select";
export * from "./input/Autocomplete";
export { default as PasswordInput } from "./input/PasswordInput";

