import { SVGProps } from "react";

export function TargetIcon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  fill = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg {...{ width, height, viewBox }} fill="none" xmlns={xmlns} {...props}>
      <path
        d="M8.91094 6.33045C9.40599 6.23211 9.80203 5.64203 9.60401 5.1503C9.505 4.65857 8.91094 4.26519 8.41589 4.46188C5.74263 5.05196 3.96045 7.41226 3.96045 10.1659C3.96045 13.4114 6.63372 16.0667 9.90104 16.0667C12.9704 16.0667 15.5446 13.7064 15.8416 10.6577C15.9406 10.1659 15.4456 9.67421 14.9505 9.57587C14.4555 9.47752 13.9604 9.96925 13.8614 10.461C13.6634 12.4279 11.8812 14.0998 9.90104 14.0998C7.72283 14.0998 5.94065 12.3295 5.94065 10.1659C5.94065 8.29737 7.12877 6.72384 8.91094 6.33045Z"
        fill={fill}
      />
      <path
        d="M18.8119 9.18259C18.2178 9.18259 17.8218 9.57598 17.8218 10.1661C17.8218 14.4933 14.2574 18.0337 9.90099 18.0337C5.54455 18.0337 1.9802 14.4933 1.9802 10.1661C1.9802 6.03553 5.24752 2.59342 9.40594 2.29838C10 2.29838 10.396 1.80665 10.297 1.21658C10.297 0.626505 9.80198 0.233121 9.20792 0.331467C4.05941 0.724851 0 4.95372 0 10.1661C0 15.5751 4.45545 20.0006 9.90099 20.0006C15.3465 20.0006 19.802 15.5751 19.802 10.1661C19.802 9.57598 19.3069 9.18259 18.8119 9.18259Z"
        fill={fill}
      />
      <path
        d="M11.7822 6.92109L9.20792 9.47808C8.81188 9.87146 8.81188 10.4615 9.20792 10.8549C9.40594 11.0516 9.70297 11.15 9.90099 11.15C10.099 11.15 10.396 11.0516 10.5941 10.8549L13.1683 8.29793L14.8515 8.78966C14.9505 8.78966 15.0495 8.78966 15.1485 8.78966C15.4455 8.78966 15.6436 8.69131 15.8416 8.49462L19.703 4.56079C19.901 4.3641 20 3.97071 20 3.67568C20 3.38064 19.703 3.0856 19.4059 2.98725L17.6238 2.39718L17.0297 0.626955C16.9307 0.331917 16.6337 0.0368797 16.3366 0.0368797C16.0396 -0.0614661 15.6436 0.0368797 15.4455 0.331917L11.5842 4.26575C11.2871 4.56079 11.1881 4.95417 11.3861 5.24921L11.7822 6.92109ZM15.6436 2.88891L15.9406 3.57733C16.0396 3.87237 16.2376 4.06906 16.5347 4.16741L17.2277 4.46244L14.8515 6.82274L13.6634 6.42936L13.2673 5.24921L15.6436 2.88891Z"
        fill={fill}
      />
    </svg>
  );
}

export function SmTargetIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={16}
      viewBox={"0 0 15 16"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M6.875 5.56241C7.1875 5.49991 7.4375 5.12491 7.3125 4.81241C7.25 4.49991 6.875 4.24991 6.5625 4.37491C4.875 4.74991 3.75 6.24991 3.75 7.99991C3.75 10.0624 5.4375 11.7499 7.5 11.7499C9.4375 11.7499 11.0625 10.2499 11.25 8.31241C11.3125 7.99991 11 7.68741 10.6875 7.62491C10.375 7.56241 10.0625 7.87491 10 8.18741C9.875 9.43741 8.75 10.4999 7.5 10.4999C6.125 10.4999 5 9.37491 5 7.99991C5 6.81241 5.75 5.81241 6.875 5.56241Z"
        fill="#667085"
      />
      <path
        d="M13.125 7.37493C12.75 7.37493 12.5 7.62493 12.5 7.99993C12.5 10.7499 10.25 12.9999 7.5 12.9999C4.75 12.9999 2.5 10.7499 2.5 7.99993C2.5 5.37493 4.5625 3.18743 7.1875 2.99993C7.5625 2.99993 7.8125 2.68743 7.75 2.31243C7.75 1.93743 7.4375 1.68743 7.0625 1.74993C3.8125 1.99993 1.25 4.68743 1.25 7.99993C1.25 11.4374 4.0625 14.2499 7.5 14.2499C10.9375 14.2499 13.75 11.4374 13.75 7.99993C13.75 7.62493 13.4375 7.37493 13.125 7.37493Z"
        fill="#667085"
      />
      <path
        d="M8.6875 5.9375L7.0625 7.5625C6.8125 7.8125 6.8125 8.1875 7.0625 8.4375C7.1875 8.5625 7.375 8.625 7.5 8.625C7.625 8.625 7.8125 8.5625 7.9375 8.4375L9.5625 6.8125L10.625 7.125C10.6875 7.125 10.75 7.125 10.8125 7.125C11 7.125 11.125 7.0625 11.25 6.9375L13.6875 4.4375C13.8125 4.3125 13.875 4.0625 13.875 3.875C13.875 3.6875 13.6875 3.5 13.5 3.4375L12.375 3.0625L12 1.9375C11.9375 1.75 11.75 1.5625 11.5625 1.5625C11.375 1.5 11.125 1.5625 11 1.75L8.5625 4.25C8.375 4.4375 8.3125 4.6875 8.4375 4.875L8.6875 5.9375ZM11.125 3.375L11.3125 3.8125C11.375 4 11.5 4.125 11.6875 4.1875L12.125 4.375L10.625 5.875L9.875 5.625L9.625 4.875L11.125 3.375Z"
        fill="#667085"
      />
    </svg>
  );
}
