import { BIZ_DOMAIN, SOURCING_DOMAIN } from "constants/schemas";

export const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  if (parts.length > 1) {
    return parts[0];
  } else {
    return null;
  }
};

export const isSameDomain = (url?: string) => {
  try {
    if (!url) return false;
    const currentDomain = `${window.location.protocol}//${window.location.host}`;
    const urlObj = new URL(url);
    const urlDomain = `${urlObj.protocol}//${urlObj.host}`;
    const bizDomains = [SOURCING_DOMAIN, BIZ_DOMAIN];
    return (
      currentDomain === urlDomain ||
      (bizDomains.indexOf(currentDomain) > -1 &&
        bizDomains.indexOf(urlDomain) > -1)
    );
  } catch (error) {
    return false;
  }
};

export const isSameSubDomain = (url?: string) => {
  try {
    if (!url) return false;
    const currentDomain = `${window.location.protocol}//${window.location.host}`;
    const urlObj = new URL(url);
    const urlDomain = `${urlObj.protocol}//${urlObj.host}`;
    return currentDomain === urlDomain;
  } catch (error) {
    return false;
  }
};

export const concatUrl = (pathname: string, search: string) =>
  pathname + "?" + search;
