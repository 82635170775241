import { SVGProps } from "react";

export function PackageIcon({
  width = 20,
  height = 23,
  viewBox = "0 0 20 23",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M14.5 8.3999L5.5 3.2099M1.27 5.9599L10 11.0099L18.73 5.9599M10 21.0799V10.9999M19 14.9999V6.9999C18.9996 6.64918 18.9071 6.30471 18.7315 6.00106C18.556 5.69742 18.3037 5.44526 18 5.2699L11 1.2699C10.696 1.09437 10.3511 1.00195 10 1.00195C9.64893 1.00195 9.30404 1.09437 9 1.2699L2 5.2699C1.69626 5.44526 1.44398 5.69742 1.26846 6.00106C1.09294 6.30471 1.00036 6.64918 1 6.9999V14.9999C1.00036 15.3506 1.09294 15.6951 1.26846 15.9987C1.44398 16.3024 1.69626 16.5545 2 16.7299L9 20.7299C9.30404 20.9054 9.64893 20.9979 10 20.9979C10.3511 20.9979 10.696 20.9054 11 20.7299L18 16.7299C18.3037 16.5545 18.556 16.3024 18.7315 15.9987C18.9071 15.6951 18.9996 15.3506 19 14.9999Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SmPackageIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={16}
      viewBox={"0 0 15 16"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M10.3125 6.37506L4.6875 3.13131M2.04375 4.85006L7.5 8.00631L12.9562 4.85006M7.5 14.3001V8.00006M13.125 10.5001V5.50006C13.1248 5.28086 13.0669 5.06557 12.9572 4.87579C12.8475 4.68601 12.6898 4.52841 12.5 4.41881L8.125 1.91881C7.93498 1.8091 7.71942 1.75134 7.5 1.75134C7.28058 1.75134 7.06502 1.8091 6.875 1.91881L2.5 4.41881C2.31016 4.52841 2.15249 4.68601 2.04279 4.87579C1.93309 5.06557 1.87522 5.28086 1.875 5.50006V10.5001C1.87522 10.7193 1.93309 10.9346 2.04279 11.1243C2.15249 11.3141 2.31016 11.4717 2.5 11.5813L6.875 14.0813C7.06502 14.191 7.28058 14.2488 7.5 14.2488C7.71942 14.2488 7.93498 14.191 8.125 14.0813L12.5 11.5813C12.6898 11.4717 12.8475 11.3141 12.9572 11.1243C13.0669 10.9346 13.1248 10.7193 13.125 10.5001Z"
        stroke="#667085"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
