import { IconButton } from "@mui/material";
import { Input, InputProp } from "components/Input";
import { forwardRef, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const PasswordInput = forwardRef<any, InputProp>(
  ({ toplabel, requiredlabel, ...props }, ref) => {
    const [showPw, setShowPw] = useState(false);

    const onClickShowPw = () => {
      setShowPw(!showPw);
    };

    return (
      <Input
        toplabel={toplabel}
        requiredlabel={requiredlabel}
        type={showPw ? "text" : "password"}
        InputProps={{
          ...props.InputProps,
          endAdornment: (
            <IconButton
              className="fit eye-icon"
              onClick={onClickShowPw}
              edge="end"
            >
              {showPw ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          ),
        }}
        ref={ref}
        {...props}
      />
    );
  }
);

export default PasswordInput;
