import {
  TextFieldProps,
  TextField,
  styled,
  StackProps,
  Stack,
  SelectProps,
  Select as SelectLib,
  MenuItem,
} from "@mui/material";
import { forwardRef } from "react";
import { SmText } from "./Text";
import { IoMdClose } from "react-icons/io";
import { OptionType } from "types/common";

type FakeInputType = {
  error?: boolean;
};

export type InputProp = TextFieldProps & {
  toplabel?: string;
  requiredlabel?: boolean;
  stackProps?: StackProps;
};

export const Input = forwardRef((props: InputProp, ref: any) => {
  const { requiredlabel, toplabel, stackProps, ...rest } = props;
  if (!toplabel) return <BaseInput fullWidth ref={ref} {...props} />;
  return (
    <Stack spacing={1} {...stackProps}>
      <SmText className={"input-label" + (requiredlabel ? " required" : "")}>
        {toplabel}
      </SmText>
      <BaseInput fullWidth ref={ref} {...rest} />
    </Stack>
  );
});

export const RoundedInput = forwardRef((props: TextFieldProps, ref: any) => {
  return (
    <StyledRoundedInput
      ref={ref}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
});

export const FakeInput = (props: StackProps & FakeInputType) => {
  return <StyledFakeInput {...props} error={props.error} />;
};

const StyledRoundedInput = styled(TextField)({});

const StyledFakeInput = styled(Stack)<{ error?: boolean }>(({ error }) => ({
  padding: "9px 14px",
  border: `1px solid ${error ? "#f03e3e !important" : "#D0D5DD"}`,
  borderRadius: 8,
  ":hover": {
    borderColor: "#000",
  },
  ".MuiInputBase-input": {
    display: "none",
  },
  cursor: "pointer",
}));

export const BelowSelect = (
  props: SelectProps & {
    toplabel?: string;
    customValue?: any[];
    options?: any[];
    showBelow?: boolean;
    onDeleteSelect?: (value: string | number) => void;
  }
) => {
  const {
    placeholder,
    showBelow = true,
    customValue,
    options,
    onDeleteSelect = () => {},
    toplabel,
  } = props;

  const Common = () => (
    <BaseSelect
      displayEmpty
      renderValue={() => (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <span className="placeholder">{placeholder}</span>
        </div>
      )}
      defaultValue={[]}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 360,
          },
        },
      }}
      {...props}
    >
      {options?.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option?.label}
        </MenuItem>
      ))}
    </BaseSelect>
  );

  return (
    <Stack spacing={1}>
      {!toplabel && <Common />}

      {toplabel && (
        <>
          <SmText className="input-label">{toplabel}</SmText>
          <Common />
        </>
      )}

      {showBelow && (
        <BelowChips {...{ options, customValue, onDeleteSelect }} />
      )}
    </Stack>
  );
};

export const BelowChips = ({
  customValue = [],
  options,
  onDeleteSelect,
}: {
  customValue?: any[];
  options?: OptionType[];
  onDeleteSelect?: (value: string | number) => void;
}) => {
  if (customValue?.length < 1) return null;
  return (
    <Stack direction="row" flexWrap="wrap" gap={0.5}>
      {(customValue || []).map((v: any, i: number) => {
        const selectedLabel =
          typeof v === "string"
            ? options?.find((o) => o.value === v)?.label
            : v?.label;

        return (
          <SelectChip key={i}>
            <SmText>{selectedLabel}</SmText>
            <IoMdClose onClick={() => onDeleteSelect?.(v)} />
          </SelectChip>
        );
      })}
    </Stack>
  );
};

export const BaseInput = styled(TextField)(({ theme }) => ({
  ".MuiInputBase-root": {
    overflow: "hidden",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    padding: "10px 14px",
  },
  "& .MuiOutlinedInput-input": {
    padding: 0,
  },
  ".MuiInputAdornment-root, .MuiInputAdornment-root p": {
    fontSize: 14,
  },
  ".eye-icon": {
    svg: {
      fontSize: 20,
      marginRight: 8,
    },
  },
  button: {
    fontSize: 14,
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    input: {
      fontSize: 12,
    },
    "& .MuiInputBase-root": {
      padding: "10px 14px",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
      fontSize: 12,
    },
    ".MuiInputAdornment-root, .MuiInputAdornment-root p, button": {
      fontSize: 12,
    },
  },
}));

const SelectChip = styled("div")({
  borderRadius: 8,
  padding: 8,
  border: "1px solid #E0E0E0",
  display: "flex",
  gap: 8,
  alignItems: "center",
  backgroundColor: "#fff",
  svg: {
    cursor: "pointer",
  },
});

export const BaseSelect = styled(SelectLib)(({ theme }) => ({
  ".MuiSelect-select": {
    padding: "10px 14px",
    backgroundColor: "#fff",
    "&,& p": {
      fontSize: 14,
    },
  },
  ".MuiInputBase-root": {},
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    ".MuiSelect-select": {
      padding: "7px 14px",
      "&,& p": {
        fontSize: 12,
      },
    },
    ".MuiInputAdornment-root p": {
      fontSize: 12,
    },
  },
}));
